@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  body {
    @apply font-sans antialiased;
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(122, 157, 255, 0),
    hsla(242, 74%, 61%, 0)
  )!important;
}

@keyframes rotate-cog {
  0% {
    transform: rotate(0deg);
  }

  /* Step 1 */
  5% {
    transform: rotate(60deg);
  }
  10% {
    transform: rotate(53deg);
  }
  15% {
    transform: rotate(60deg);
  }
  20% {
    transform: rotate(60deg);
  }

  /* Step 2 */
  25% {
    transform: rotate(120deg);
  }
  30% {
    transform: rotate(113deg);
  }
  35% {
    transform: rotate(120deg);
  }
  40% {
    transform: rotate(120deg);
  }

  /* Step 3 */
  45% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(173deg);
  }
  55% {
    transform: rotate(180deg);
  }
  60% {
    transform: rotate(180deg);
  }

  /* Step 4 */
  65% {
    transform: rotate(240deg);
  }
  70% {
    transform: rotate(233deg);
  }
  75% {
    transform: rotate(240deg);
  }
  80% {
    transform: rotate(240deg);
  }

  /* Step 5 */
  85% {
    transform: rotate(300deg);
  }
  90% {
    transform: rotate(293deg);
  }
  95% {
    transform: rotate(300deg);
  }
  100% {
    transform: rotate(300deg);
  }
}

#Cog_x5F_2 {
  animation: rotate-cog 20s ease-in-out infinite;
  transform-origin: center;
}